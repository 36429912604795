<template>
  <div my-info>
    <SubHeader :title="$t(`myInfo.title.myInfo`)" />
    <div class="info-router-group">
      <h3>{{ accountTitle }}</h3>
      <MenuLinkButton :name="emailAddressTitle" @click="move('NpEmailAddress', isOnboardingRedirect, emailRegister)" :register="emailRegister" green-boarder :hidden-right="emailRegister" icon="envelope-light" :load="load" />
      <MenuLinkButton v-if="!isOCNP" :name="mobileNumberTitle" @click="move('NpMobileNumber', isOnboardingRedirect, isMfaMobile)" green-boarder :register="!mobileNumber" :hidden-right="isMfaMobile || hiddenMenu('NpMobileNumber')" icon="mobile-regular" :load="load" />
      <MenuLinkButton v-if="isGGPass" icon="message-circle-regular" :name="$t('myInfo.title.linkedSocialAccounts')" @click="move('NpSocialAccounts', false, false,)" />
    </div>
    <div v-for="item in siteList" class="info-router-group">
      <h3>{{ $t(`myInfo.title.${item.title}`) }}</h3>
      <MenuLinkButton v-for="child in item.children" :switch-value="isEnableFundPassword" @click="move(child.name, child.toOnboarding)" @switch="fundPassword(child.name)" :name="getItemLabel(child.label)" :key="child.name" :icon="child.icon" :hidden-right="hiddenMenu(child.name)" :load="load" :isMfaBadge="hasBadgeMenu(child.name)" :badgeType="getBadgeType(child.name)" :is-switch="isFundPassword(child.name)" />
    </div>
  </div>
</template>

<script>
import { getSiteList, routeNames, useOnBoardingList } from '@/constants/model/my-page';
import { state } from '@shared/utils/storeUtils.mjs';
import { isExcludeGgpass } from '@/utils/baseUtil';
import { isArray } from 'lodash';
import MenuLinkButton from '@/views/components/gg-pass/MenuLinkButton.vue';
import SubHeader from '@/views/components/gg-pass/SubHeader.vue';
import Specific from '@shared/types/Specific';
import { excludeSkipRoutes } from '@/constants/model/my-page';
import { siteIds } from '@/constants/base/siteMap';
import ChangeFundPasswordModal from '@/views/components/pages/my-page/modal/ChangeFundPasswordModal.vue';

export default {
  name: 'MyInfo',
  components: { SubHeader, MenuLinkButton },
  props: {
    structure: Specific,
    load: { type: Boolean, default: false },
  },
  data() {
    return {
      emailAddress: null,
      mobileNumber: null,
      mobileCountryCode: null,
      isEmailVerified: false,
      isMfaMobile: false,
    };
  },
  computed: {
    site: state('env', 'site'),
    brandId: state('env', 'gpBrand'),
    brand: state('env', 'brand'),
    userInfo: state('user', 'userInfo'),
    onePassInfo: state('user', 'onePassInfo'),
    token: state('user', 'token'),
    siteList() {
      return getSiteList(this.site, this.structure.onBoardingStepLength);
    },
    emailAddressTitle() {
      return this.emailAddress ? this.emailAddress : this.$t('emailAddress');
    },
    mobileNumberTitle() {
      if (!this.isGGPass) {
        return this.mobileNumber ? `+${this.mobileCountryCode} ${this.mobileNumber}` : this.$t('mobileNumber');
      }
      return this.mobileNumber ? `${this.onePassInfo.countryCode} ${this.mobileNumber}` : this.$t('mobileNumber');
    },
    applePrivateEmail() {
      if (this.onePassInfo?.oauths && this.onePassInfo.oauths.length > 0) {
        const applePrivate = this.onePassInfo.oauths.find(auth => auth.oauthType === 'APPLE');
        if (!applePrivate) return null;
        return applePrivate.isPrivateEmail ? applePrivate.oauthEmail : null;
      } else return null;
    },
    emailRegister() {
      if (this.isGGPass) {
        if (!!this.applePrivateEmail) {
          if(this.applePrivateEmail !== this.emailAddressTitle) return this.isEmailVerified;
          return !this.isEmailVerified;
        }
        return this.isEmailVerified
      } else {
        return this.isEmailVerified;
      }
    },
    isGGPass() {
      return !isExcludeGgpass(this.site);
    },
    isGGPDE() {
      return this.site === siteIds.GGPDE;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    isOCNP() {
      return this.site === siteIds.OCNP;
    },
    accountTitle() {
      if (!this.isGGPass) {
        const brand = this.isWSOP ? 'GGPoker.ca' : this.brand;
        return `${brand} ID`;
      }
      return this.$t(`myInfo.title.ggpassId`);
    },
    useOnboardingSite() {
      return useOnBoardingList.includes(this.site);
    },
    isOnboardingRedirect() {
      if (this.useOnboardingSite) return true;
      return false;
    },
    exclusionSkipMenus() {
      return excludeSkipRoutes;
    },
    isEnableFundPassword() {
      return this.userInfo.IsEnableFundPassword
    },
  },
  methods: {
    async initialize() {
      if (this.isGGPass) await this.ggpassInitialize();
      else await this.npInitialize();
      this.resetModel();
    },

    async ggpassInitialize() {
      // this.emailAddress = this.onePassInfo.email;
      if (!!this.applePrivateEmail) {
        this.emailAddress = !!this.onePassInfo.email ? this.onePassInfo.email : this.applePrivateEmail;
      } else {
        this.emailAddress = this.onePassInfo.email;
      }
      this.isEmailVerified = this.onePassInfo.isEmailVerified && !!this.onePassInfo.email;
      this.mobileNumber = this.onePassInfo.mobileNumber;
    },

    async npInitialize() {
      this.emailAddress = this.userInfo?.Email;
      this.isEmailVerified = this.userInfo?.IsEmailVerified;
      this.mobileNumber = this.userInfo?.MobileNumber;
      this.mobileCountryCode = this.userInfo?.MobileCountryCode || '';

      this.setMobileMfa();
    },

    setMobileMfa() {
      const mfaSetting = this.userInfo.MfaSetting || null;
      if (!mfaSetting) return false;
      this.isMfaMobile = mfaSetting?.VerificationMethod === 'Mobile';
    },
    async getOnboardingStep() {
      const result = await this.$services.onboarding.getOrder();
      if (result.error) {
        return;
      }
      if (isArray(result)) {
        return result.length;
      } else {
        return 0;
      }
    },

    async move(name, toOnboarding, auth = false) {
      if (auth) return;
      if (toOnboarding) {
        const length = await this.getOnboardingStep();
        if (length > 0) this.$myinfo.redirectKycUrl({ lang: this.$i18n('lang') });
        else this.replaceRouteName(name);
        return;
      } else {
        if (this.hiddenMenu(name)) return;
        else this.replaceRouteName(name);
        return;
      }
    },


    getItemLabel(item) {
      let defaultString = 'myInfo.title.';
      if (item.hasOwnProperty(this.site + '')) {
        defaultString += item[this.site];
      } else defaultString += item.default;

      return this.$t(`${defaultString}`);
    },
    resetModel() {
      this.structure.params = null;
    },
    hiddenMenu(name) {
      if (name === routeNames.NpFundPassword) return true;
      return this.exclusionSkipMenus.includes(name) && this.structure.isSelfExclusion;
    },

    hasBadgeMenu(name) {
      return name === routeNames.NpLoginSecurity;
    },

    getBadgeType(name) {
      if (!this.hasBadgeMenu(name)) return null;
      if (this.isGGPass) {
        return this.getOnePassMfaLevel();
      } else {
        const mfaLevel = this.userInfo.MfaSetting?.SecurityLevel || null;
        if (mfaLevel) return mfaLevel.toUpperCase();
        else return null;
      }
    },

    getOnePassMfaLevel() {
      const mfaArray = this.onePassInfo?.mfas || null;
      if (!mfaArray) return null;

      const enableMfaLevel = mfaArray.find(mfa => mfa.isEnabled)?.level || null;
      if (!enableMfaLevel) {
        const off = mfaArray.find(mfa => mfa.level === 'OFF')?.level || 'OFF';
        return off.toUpperCase();
      }

      return enableMfaLevel.toUpperCase();
    },
    isFundPassword(name) {
      return name === routeNames.NpFundPassword
    },
    fundPassword(name) {
      if (!this.isFundPassword(name)) return;
      this.$modal(ChangeFundPasswordModal);
    }
  },
  async mounted() {
    this.$store.commit('env/setTheme', 'white');
    await this.initialize();
  },
  beforeDestroy() {
    this.$store.commit('env/setTheme', 'dark');
  }


};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info] {
  .info-router-group { .p(10, 20, 10, 20); .flex(); .flex-dc(); .gap(8);
    > h3 {.c(@c-b08); .fs(16); font-weight: 500;}
    [gp-menu-link-button] {.flex(); .flex-row();}
  }
  .last-router {.ph(20);}
  .list-holder {
    > a { .block(); .p(16, 24, 12, 16); .-b(@c-b01); .c(@c-b08); .bold(); .rel();
      [font-icon] { .bold(); .c(@c-b05); .rt(0, 50%); .abs(); .t-yc(); }
    }
  }
}

</style>
